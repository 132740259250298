<template>
  <div class="main_right">
    <div class="Box">
      <div class="aRow">
        <!-- 词汇量 -->
        <div class="vocabulary">
          <div>
            <span>我的金币</span>
            <p>
              <span>{{ coinsSum }}</span>
              <span>个</span>
            </p>
          </div>
        </div>
        <!-- 发起兑换按钮 -->
        <div class="exchange" @click="state">
          <span>{{btnState ? '发起兑换' : '查看明细'}}</span>
        </div>
      </div>
      <div class="testpage" style="height:calc(100% - 5vw);">
        <p>
          <span>{{btnState ? '金币明细' : '金币兑换'}}</span>
        </p>
        <el-table ref="table" v-if="btnState" class="table" :data="tableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
          <el-table-column label="日期" align="center" min-width="15%">
            <template v-slot="scope">
              {{ scope.row.cjrq.slice(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column prop="lymc" label="金币来源" align="center" min-width="70%"/>
          <el-table-column label="获得金币个数" align="center" min-width="15%">
            <template v-slot="scope">
              {{ scope.row.sl > 0 ? ('+' + scope.row.sl) : scope.row.sl }}
            </template>
          </el-table-column>
        </el-table>
        <el-table ref="table" v-else class="table" :data="thingsTableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
          <el-table-column prop="bh" label="商品编号" align="center" />
          <el-table-column prop="mc" label="商品名称" align="center" />
          <el-table-column prop="sxjb" label="所需金币(个)" align="center" />
          <el-table-column prop="sl" label="剩余数量(支)" align="center" />
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <el-button type="primary" size="mini" round @click="convert(scope.row)">兑 换</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <div class="pagination" v-if="!btnState">
        <el-pagination background layout="prev, pager, next" :current-page="pages.currentPage" @current-change="handleCurrentChange" :page-size="pages.pageSize" :total="pages.total">
        </el-pagination>
      </div>
    </div>
    <div class="tips" v-if="!btnState">
      <p>如何获得金币</p>
      <p>
        <span>1.每天在单词速记上完成当天学习, 就会收到每天对应的金币奖励</span>
        <span>2.特别注意：每日的奖励和每天背的单词数多少有直接关系</span>
      </p>
    </div>
  </div>
</template>
<script>
import { selectStudentCoin, selectClassByYhid, selectExchangeItemsPage, saveItemExchange } from "@/api/student";
export default {
  data() {
    return {
      tableData: [],
      thingsTableData: [],
      btnState: true,
      coinsSum: 0, // 金币总数
      pages:{
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      bjid: '',
      bjmc: ''
    }
  },
  created() {
    selectStudentCoin({xsid: this.$store.state.userInfo.yhid}).then(res => {
      if (res.success) {
        this.tableData = res.result.list
        this.coinsSum = ~~res.result.wdjb
      } else {
        this.$message.warning(res.resultDesc)
      }
    })
    this.getThings()
  },
  methods: {
    // 获取金币列表
    getCoinsList() {
      selectStudentCoin({ xsid: this.$store.state.userInfo.yhid }).then((res) => {
        if (res.success) {
          if (res.success) {
            this.tableData = res.result.list
            this.coinsSum = ~~res.result.wdjb
          } else {
            this.$message.warning("未获得过金币，继续努力");
          }
        } else {
          this.$message.error(res.resultDesc);
        }
      })
    },
    state() {
      this.btnState = !this.btnState
      if (!this.bjid) {
        selectClassByYhid({ yhid: this.$store.state.userInfo.yhid }).then(res => {
          if (res.success) {
            this.bjid = res.result[0].bjid
            this.bjmc = res.result[0].bjmc
          } else {
            this.$message.error(res.resultDesc)
          }
        })
      }
    },
    // 换页
    handleCurrentChange(val) {
      this.pages.currentPage = val
      this.getThings()
    },
    // 获取商品列表
    getThings() {
      selectExchangeItemsPage({ currentPage: this.pages.currentPage, pageSize: this.pages.pageSize, zhid: 1, zt: true }).then(res => {
        if (res.success) {
          // this.thingsTableData = res.result.list.filter(item => {
          //   return item.sl
          // })
          this.thingsTableData = res.result.list
          this.pages.total = res.result.total
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    },
    // 兑换
    convert(data) {
      if ((this.coinsSum < data.sxjb) && (data.sxjb > 0) ) {
        this.$message.warning('您还买不起这个商品~换一种看看吧~')
        return false
      }
      if (!data.sl) {
        this.$message.warning('这个商品已经没货了~换一种看看吧~')
        return false
      }
      this.$confirm('将花费' + data.sxjb + '金币兑换1个' + data.mc, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveItemExchange({ dhrq: new Date(), jbzs: this.coinsSum, wpmc: data.mc, bjid: 402, bjlx: false, bjmd: this.bjmc, xgr: this.$store.state.userInfo.yhid, cjr: this.$store.state.userInfo.yhid, sl: 1, wpid: data.id, xsid: this.$store.state.userInfo.yhid, zhid: this.$store.state.userInfo.zhid, xsmc: this.$store.state.userInfo.realname, yycdid: sessionStorage.getItem('yycdid'), yycdmc: '单词速记V3.0', yylx: 2 }).then(res => {
          if (res.success) {
            this.getCoinsList()
            this.getThings()
            this.$message.success('兑换成功')
          } else {
            this.$message.error(res.resultDesc)
          }
        }).catch((err) => {
          console.log(err)
          this.$message.warning('库存整改中')
        })
      }).catch(() => {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main_right {
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .Box {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 2%;
    box-sizing: border-box;
    position: relative;
  }
  .vocabulary {
    width: 30%;
    height: 3vw;
    float: left;
    div {
      width: 14vw;
      height: 3vw;
      background: #f1f8ff;
      border-radius: 10px;
      line-height: 3vw;
      box-sizing: border-box;
      padding: 0 3%;
      color: #333;
      span {
        display: block;
      }
      span:nth-child(1) {
        // display: block;
        font-size: 1.1vw;
        float: left;
      }
      p {
        float: right;
        span:nth-child(1) {
          font-size: 1.5vw;
          color: #409eff;
          font-weight: 600;
          float: left;
          margin-right: 1.5vw;
        }
        span:nth-child(2) {
          font-size: 1vw;
          float: left;
        }
      }
    }
  }
  .pagination{
    position: absolute;
    bottom: 1vw;
    right: 3vw;
    z-index: 1;
  }
  .testpage {
    width: 100%;
    position: relative;
    p:nth-child(1) {
      width: 100%;
      height: 2.5vw;
      text-align: center;
      position: relative;
      color: #333;
      font-size: 1.5vw;
      line-height: 2.5vw;
    }
  }
  .table {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2vw;
    top: 4vw;
  }
  .aRow {
    width: 100%;
    height: 3vw;
  }
  .aRow:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .exchange {
    width: 7vw;
    float: right;
    height: 2.5vw;
    background: #5990ff;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 30px;
    margin-top: 0.25vw;
    color: #fff;
    font-size: 1vw;
    position: relative;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
    }
  }
  .tips {
    position: absolute;
    bottom: 1%;
    left: 4%;
    width: 100%;
    p:nth-child(1) {
      font-size: 1vw;
      color: #333;
      width: 100%;
    }
    p:nth-child(2) {
      margin-top: 0.3vw;
      font-size: 0.8vw;
      color: #666;
      width: 100%;
      span:last-child {
        margin-left: 10%;
      }
    }
  }
}
/deep/.el-table {
  height: calc(100% - 13.5%);
}
</style>
<style lang="scss" scoped>
.el-table::before{
  display: none;
}
</style>