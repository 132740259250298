import axios from './index.js'
import qs from 'qs'
// 登录判断有无集训
export const isHaveTrain = params => {
  return axios.post(`api/8720/student/isHaveTrain`, params)
}

export const getmnemonic = params => {
  return axios.post(`api/8840/englishLegend/selectEnglishLegend`, params)
}
// 保存助记符学习记录
export const savemnemonic = params => {
  return axios.post(`api/8840/englishLegend/saveEnglishLegendLearned`, params)
}
// 获取该学生所配置的单元
export const getTpDyList = params => {
  return axios.post(`api/8840/student/getTpDyList`, params)
}
// 获取单元下的课文和星级
export const getTpKwList = params => {
  return axios.post(`api/8840/student/getTpKwList`, params)
}
// 获取该学生所配置的章
export const getTpZList = params => {
  return axios.post(`api/8840/student/getTpZList`, params)
}
// 判断学生是否可以图谱学习
export const isCanTp = params => {
  return axios.post(`api/8840/student/isCanTp`, params)
}
// 获取英语课文
export const getEnglishText = params => {
  return axios.post(`api/8840/study/getEnglishText`, params)
}
// 获取英语课文生词
export const getEnglishTextWord = params => {
  return axios.post(`api/8840/study/getEnglishTextWord`, params)
}
// 获取英语课文内容
export const getTextContent = params => {
  return axios.post(`api/8840/study/getEnglishTextContent`, params)
}
// 获取英语课文内容关键字
export const getTextKeys = params => {
  return axios.post(`api/8840/study/getEnglishTextContentKey`, params)
}
// 获取英语课文下的单选题
export const getExercises = params => {
  return axios.post(`api/8840/study/getTextExercises`, params)
}
// 获取断点
export const getMyBreakpoint = params => {
  return axios.post(`api/8840/myBreakpoint/getMyBreakpoint`, params)
}
// 保存断点
export const saveMyBreakpoint = params => {
  return axios.post(`api/8840/myBreakpoint/saveMyBreakpoint`, params)
}
// 保存学习成绩
export const saveLearnedTextScore = params => {
  return axios.post(`api/8840/study/saveLearnedTextScore`, params)
}
  
// -----------------------------------学习报告
// 集训下拉框
export const getTrains = params => {
  return axios.get(`api/8100/trainings/selectTraining`, params)
}
// 学情分析
export const studyReportApi = params => {
  return axios.post(`api/8840/studyAnalysis/getStudyAnalysis`, params)
}
// -----------------------------------个人中心
// 获取金币数量和明细
export const selectStudentCoin = params => {
  return axios.post(`api/8840/studentCoin/selectStudentCoin`, params)
}

// 分页查询物品
export const selectExchangeItemsPage = (params) => {
    return axios.post(`api/8700/exchangeItems/selectExchangeItemsPage`, params)
}
// 发起兑换
export const saveItemExchange = (params) => {
    return axios.post(`api/8700/itemExchange/saveItemExchange`, params)
}
// 查询该学生金币明细
export const getCoinsList = params => {
    return axios.post(`api/8720/studentCoinDetail/getStudentCoinDetailList`, params)
}
// 金币排行榜
export const studentCoinRank = params => {
  return axios.get(`api/8820/studentCoin/studentCoinRank`, params)
}
// -----------------------------------个人设置
// 新增个性化设置
export const addPersonalConfig = params => {
  return axios.post(`api/8720/personalConfig/addPersonalConfig`, params)
}
// 我的个性化设置
export const myPersonalConfig = params => {
  return axios.post(`api/8720/personalConfig/myPersonalConfig`, params)
}
// 修改个性化设置
export const updatePersonalConfig = params => {
  return axios.post(`api/8720/personalConfig/updatePersonalConfig`, params)
}
// 获取班级（常规班）id
export const selectClassByYhid = (params) => {
  return axios.get(`api/8100/class/selectClassByYhid`, params)
}
// 意见反馈提交
export const addFeedback = params => {
  return axios.post(`api/8720/feedback/addFeedback`, params)
}
// ----------------------------------------------教学视频
// 获取教学视频
export const getTextVideo = params => {
  return axios.post(`api/8840/study/getTextVideo`, params)
}
// 保存教学视频学习数据
export const saveTextVideo = params => {
  return axios.post(`api/8840/study/saveTextVideo`, params)
}
// --------------------------------------家长端
// 学情分析
// export const studyAnalysisApi = params => {
//   return axios.post(`api/8810/studyAnalysis/getStudyAnalysis`, params)
// }
// 家长端学生下拉接口
export const parentselect = params => {
  return axios.get(`api/8100/class/selectTempClassStudents`, params)
}
// 集训下拉
export const jxselect = params => {
  return axios.get(`api/8100/class/selectTempTraining`, params)
}
// 家长端学情分析
export const parentXqfx = (params) => {
    return axios.post(`api/8810/studyAnalysis/getStudyAnalysis`, params)
}
//家长端进步曲线
export const parentJbqx = (params) => {
  return axios.post(`api/8810/studyAnalysis/getStudyCvs`, params)
}
// 获取教学视频的课文列表
export const getTextVideoText = params => {
  return axios.post(`api/8840/study/getTextVideoText`, params)
}
// -------------------------------------------------------
// 二次学习保存接口
export const savesecontStudy = params => {
  return axios.post(`api/8840/study/saveNextLearnedTextScore`, params)
}
// 科大讯飞语音识别ID
export const getAPPID = params => {
  return axios.post(`api/8100/speech/getSpeechConfig`, params)
}
